import React, {useState} from 'react';
import {UIButton} from '../ui/button/button.js';
import {Notification} from './notification.js';
import {UIModal} from '../ui/modal/modal.js';
import {UsersKeysCreate} from '../../page/users/keys/create.js';
import {UsersKeysVerify} from '../../page/users/keys/verify.js';
import {useUser} from '../../model/use_user.js';
import {ReactComponent as IconKey} from '../../assets/icons/icon-key.svg';

const useNotifications = function(){
    const user = useUser();
    const [modal, setModal] = useState({isVisible: false});

    if(! user){
        return null;
    }

    let notification = null;
    if(user.isSGTS && ! user.publicKey){
        // no personal key set yet.
        const openModalCreateKeys = () => {
            setModal({
                isVisible: true,
                title: 'Create new keypair',
                content: <UsersKeysCreate updateModal={setModal}/>
            });
        };
        notification = <Notification Icon={IconKey}>
            <h2>Setup personal keypair</h2>
            <UIButton onClick={openModalCreateKeys}>Create keypair</UIButton>
            <p>
                You haven&lsquo;t setup your personal keypair yet. This is required to execute transactions.
            </p>
        </Notification>;
    }else if(user.isSGTS && user.encMasterKey && ! user.signedMasterKey){
        // no personal key set yet.
        const openModalVerifyKey = () => {
            setModal({
                isVisible: true,
                title: 'Verify master key',
                content: <UsersKeysVerify updateModal={setModal}/>
            });
        };
        notification = <Notification Icon={IconKey}>
            <h2>Verify master key</h2>
            <UIButton onClick={openModalVerifyKey}>Verify master key</UIButton>
            <p>
                A master key was setup for you. Verify this key with what you received from the admin to
                activate your access.
            </p>
        </Notification>;
    }

    return <>
        {notification}
        <UIModal visible={modal.isVisible} title={modal.title} width={800} height={450}
                onClose={() => {setModal({isVisible: false});}}>
            {modal.content}
        </UIModal>
    </>;
};

export {useNotifications};
