import React, {useState} from 'react';
import {PropTypes} from 'prop-types';
import {WalletsRecovery} from './recovery.js';
import {AuthProtectionSecret} from '../../components/auth/protection-secret.js';
import {FeedbackError} from '../../components/feedback/error.js';
import {ModelSgtsJs} from '../../model/sgts-js.js';
import {Loader} from '../../helpers/elements/loader.js';

function WalletsCreate(props){
    const [wallet, setWallet] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const createBusinessUnit = async (protectionSecret) => {
        try {
            setIsLoading(true);
            const unit = await globalThis.BuManagementApi.addBusinessUnits(
                    props.unit.name, props.unit.branch, props.unit.bpNumber);
            console.log('Unit created');
            console.log(unit);
        } catch (err){
            console.log(err);
            setError('Failed to create business unit. ' + err);
        }

        await createWallet(protectionSecret);
        setIsLoading(false);
    };
    const createWallet = async (protectionSecret) => {
        try {
            setIsLoading(true);

            const wallet = await globalThis.Wallet.generateWallet(props.unit.name, protectionSecret);
            console.log('Wallet created');
            console.log(wallet);

            props.setModalTitle('Wallet created');
            ModelSgtsJs.invalidateAllInstances(ModelSgtsJs);
            setWallet(wallet);
        } catch (err){
            console.log(err);
            // error handling
            setError('Failed to create unit/wallet. ' + err);
        }
        setIsLoading(false);
    };
    if(wallet){
        return <WalletsRecovery unit={props.unit.name} wallet={wallet} onClose={props.onClose}/>;
    }

    return <div style={{position: 'relative'}}>
        {isLoading ? <Loader/> : null}
        <p>
            A new wallet will be created for this business unit. Please specify your passphrase to encrypt it.
            <br/><br/>
        </p>
        <FeedbackError error={error}/>
        {props.isCreatingBU ?
                <AuthProtectionSecret submitCaption="Create Business Unit" setSecret={createBusinessUnit}/> :
                <AuthProtectionSecret submitCaption="Create Wallet" setSecret={createWallet}/>}
    </div>;
}

WalletsCreate.propTypes = {
    unit: PropTypes.shape({
        name: PropTypes.string.isRequired,
        branch: PropTypes.string.isRequired,
        bpNumber: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
    }).isRequired,
    setModalTitle: PropTypes.func,
    onClose: PropTypes.func,
    isCreatingBU: PropTypes.bool
};

WalletsCreate.defaultProps = {
    setModalTitle: () => {},
    onClose: () => {},
    isCreatingBU: true
};

export {WalletsCreate};
